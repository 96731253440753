import React from 'react'
import parse from "html-react-parser"

function TextBlock({ content }) {
  return (
    <div className="card mt-3 mb-3">
      <div className="card-title card-title-colored">
        Qui sommes-nous ?
      </div>
      
      <div className="card-body">
        {content ? parse(content) : <span>Aucune description pour le moment.</span>}
      </div>
    </div>
  );
}

export default TextBlock;