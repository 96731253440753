import React from 'react'
import appStore from './../../static/apple-store.png'
import logo from '../../static/logo.png'
import playStore from './../../static/play-store.png'

function setCookie() {
  const d = new Date();
  const year = d.getFullYear();
  const month = d.getMonth();
  const day = d.getDate();
  const c = new Date(year + 1, month, day);
  document.cookie = `ducotedemaville_modalDownload=true; expires=${c}`;
}

function closeModal() {
  document.getElementById("AppDownloadModal").classList.remove("show", "d-block");
}

function appDownload() {
  const show = document.cookie.indexOf("ducotedemaville_modalDownload") > -1;

  return (
    <div id="AppDownloadModal" className={show ? 'modal fade d-none' : 'modal fade show d-block'} tabIndex="-1" role="dialog">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
          <h5 className="modal-title">Télécharger l'application !</h5>
            <button onClick={() => closeModal()} type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <img className="navbar-brand mb-4" alt="Logo" src={logo} />
            <p>Télécharger l'application "du côté de ma ville" pour retrouver tous les bons plans sur votre ville.</p>
            <p>Une application ludique et pratique pour naviguer en toute simplicité.</p>
            <div className="apps">
              <a target="_blank" rel="noreferrer noopener" href="https://itunes.apple.com/app/id1449828446">
                <img src={appStore} alt="" className="ios"/>
              </a>

              <a target="_blank" rel="noreferrer noopener" href="https://play.google.com/store/apps/details?id=cote.ville.app.als">
                <img src={playStore} alt="" className="android" />
              </a>
            </div>
            <div className="form-check">
              <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" onClick={() => setCookie()} />
              <label className="form-check-label" htmlFor="defaultCheck1">Ne plus me le rappelez</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default appDownload;
