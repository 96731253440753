import React from 'react'
import { Link } from 'react-router-dom'

const Breadcrumbs = ({ city, cat, subcat, shop, citySlug, catSlug, subcatSlug, shopSlug }) => (
  <nav aria-label="breadcrumb">
    <ol className="breadcrumb">
      <li className="breadcrumb-item">
        <Link to={`/villes/${citySlug}`}>{city}</Link>
      </li>
      <li className="breadcrumb-item">
        <Link to={`/villes/${citySlug}/categories/${catSlug}`}>{cat}</Link>
      </li>
      <li className="breadcrumb-item">
        <Link to={`/villes/${citySlug}/categories/${catSlug}/souscategorie/${subcatSlug}`}>{subcat}</Link>
      </li>
      <li className="breadcrumb-item active" aria-current="page">
        <Link to={`/villes/${citySlug}/boutiques/${shopSlug}`}>{shop}</Link>
      </li>
    </ol>
  </nav> 
)

export default Breadcrumbs;
