import React, { Component } from 'react'
import { Query } from 'react-apollo'
import Loader from './utils/Loader'
import SearchBar from './utils/SearchBar'
import PartnerCard from './cards/PartnerCard'
import { GET_SHOPS } from './../gql/request'
import { Helmet } from "react-helmet"
import NoData from './utils/NoData'
import { getCurrentCityHelper } from './helpers/currentCityHelper.js'

export default class Shops extends Component {

  state = {
    search: '',
    cities: [],
  }

  searchShop(value) {
    if (value.trim() !== this.state.search) {
      this.setState({ search: value.trim() });
    }
  }

  getDataChecked(data) {
    this.setState({ cities: data.cities });
  }

  render() {
    const { cities } = this.state;

    return (
      <div className="shops-container main-container">
        <Helmet>
          <title>Du côté de ma ville | Boutiques A-Z</title>
          <meta property="og:title" content="Du côté de ma ville | Boutiques A-Z" />
          <meta property="og:url" content={window.location.href} />
        </Helmet>

        <SearchBar placeholder="Rechercher une boutique" onSearch={(e) => this.searchShop(e)} />

        <Query query={GET_SHOPS} variables={{ search: `%${this.state.search}%`, city: getCurrentCityHelper(window.location.pathname) }}>
        {({loading, error, data: {enseignes}}) => {
          if(loading || error) return <Loader />

          let filteredShops = enseignes;
          if (cities.length > 0 ) {
            filteredShops = enseignes.filter(shop => cities.indexOf(shop.ville.slug) !== -1)
          }

          return (
            <div className="row mt-4">
              {
                filteredShops.length > 0 ?
                filteredShops.map(enseigne => {
                    return (
                      <div key={enseigne.slug} className="col-12 col-md-4 col-xl-3">
                        <PartnerCard data={enseigne} />
                      </div>
                    )
                  })
                : <NoData />
              }
            </div>
          )
        }}
        </Query>

      </div>
    )
  }
}
