import React from 'react'
import { Helmet } from "react-helmet"

const How = () => (
    <div className="how-container main-container">
        <Helmet>
          <title>Du côté de ma ville | Comment ça marche</title>
          <meta property="og:title" content="Du côté de ma ville | Comment ça marche" />
          <meta property="og:url" content={window.location.href} />
        </Helmet>
    

    <section className="how">
        <h1>Comment ça marche</h1>

        <div className="card mb-3">
            <h2>Sélectionnez votre ville</h2>
            <img src="assets/undraw_city_life.svg" alt="Plusieurs villes pour vos commerces"></img>    
            <p>Les commerces sont présent dans plusieurs villes, sélectionnez votre ville et profitez de nombreuses promotions.</p>
        </div>

        <div className="card mb-3">
            <h2>Retrouvez vos enseignes</h2>
            <img src="assets/undraw_the_search.svg" alt="Les commerces de vos villes"></img>            
            <p>Des centaines d'enseignes dans différentes catégories sont présentes dans chaque ville pour vous faire bénéficier de promotions.</p>
        </div>

        <div className="card mb-3">
            <h2>Promotions</h2>
            <img src="assets/undraw_shopping.svg" alt="Des promotions chez vos commerçants"></img>            
            <p>Trouvez les promotions qui vous intéresse et téléchargez le coupon à remettre en magasin à l'adresse indiquée.</p>
        </div>

        <div className="card mb-3">
            <h2>Abonnement</h2>
            <img src="assets/undraw_subscriber.svg" alt="Abonnez vous à vos commerces"></img>  
            <p>Inscrivez vous afin de recevoir notre newsletter et nos notifications moblie.</p>
        </div>
        
    </section>
    </div>
)

export default How;