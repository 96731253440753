import React from 'react'
import { Helmet } from "react-helmet"

const Mentions = () => (
    <div className="mentions-container main-container">
        <Helmet>
          <title>Du côté de ma ville | Mentions Légales</title>
          <meta property="og:title" content="Du côté de ma ville | Mentions Légales" />
          <meta property="og:url" content={window.location.href} />
        </Helmet>

        <div className="card mb-3">
            <div className="card-header">
                <h3>Mentions Légales <i className="fas fa-file-alt"></i></h3>
            </div>

            <div className="card-body">

                <p><strong>1. Présentation du site :<br />
                </strong><br />
                Conformément aux dispositions des articles 6-III et 19 de la Loi n° 2004-575 du 21 juin 2004 pour la Confiance dans l'économie numérique, dite L.C.E.N., nous portons à la connaissance des utilisateurs et visiteurs du site : <a href="http://www.ducotedemaville.fr" rel="noreferrer noopener" target="_blank">www.ducotedemaville.fr</a> les informations suivantes :</p>

                <p><strong>Informations légales : </strong></p>

                <p>Statut du propriétaire : <strong>societe</strong><br />
                Préfixe : <strong>SARL</strong><br />
                Nom de la Société :<strong> ALS II</strong><br />
                Adresse : <strong>27 BIS RUE CHARLES VII 94130 NOGENT SUR MARNE</strong><br />
                Tél  : <strong>0981921514</strong><br />
                Au Capital de :<strong> 150000 EUROS €</strong><br />
                SIRET :  <strong>493 159 172 000 29   </strong>R.C.S. :<strong> 493 159 172</strong><br />
                Numéro TVA intracommunautaire : <strong>FR63 493 159 172</strong><br />
                Adresse de courrier électronique : <strong>roger.aouizerate@orange.fr</strong> <br />
                <br />
                Le Créateur du site est : <strong>Du coté de ma ville</strong><br />
                Le Responsable de la  publication est : <strong>ROGER AOUIZERATE</strong><br />
                Contactez le responsable de la publication : <strong>roger.aouizerate@orange.fr</strong><br />
                Le responsable de la publication est une <strong>personne morale</strong><br />
                <br />
                Le Webmaster est  : <strong>VALENTIN STEFANESCU</strong><br />
                Contactez le Webmaster : <strong><a href="mailto:valentinstefanescu92@gmail.com?subject=Contact ï¿½ partir des mentions lï¿½gales via le site www.ducotedemaville.fr">valentinstefanescu92@gmail.com</a></strong><br />
                L’hebergeur du site est : <strong>SCALEWAY 16 RUE DE LA VILLE L'EVEQUE 75008 PARIS</strong></p>

                <p><strong>2. Description des services fournis :</strong><br />
                <br />
                Le site <a href="http://www.ducotedemaville.fr" rel="noreferrer noopener" target="_blank">www.ducotedemaville.fr</a> a pour objet de fournir une information concernant l’ensemble des activités de la société.<br />
                Le proprietaire du site s’efforce de fournir sur le site <a href="http://www.ducotedemaville.fr" rel="noreferrer noopener" target="_blank">www.ducotedemaville.fr</a> des informations aussi précises que possible. Toutefois, il ne pourra être tenue responsable des omissions, des inexactitudes et des carences dans la mise à jour, qu’elles soient de son fait ou du fait des tiers partenaires qui lui fournissent ces informations.<br />
                Tous les informations proposées sur le site <a href="http://www.ducotedemaville.fr" rel="noreferrer noopener" target="_blank">www.ducotedemaville.fr</a> sont données à titre indicatif, sont non exhaustives, et sont susceptibles d’évoluer. Elles sont données sous réserve de modifications ayant été apportées depuis leur mise en ligne.<br />
                </p>

                <p><strong>3. Propriété intellectuelle et contrefaçons :</strong></p>

                <p><br />
                Le proprietaire du site est propriétaire des droits de propriété intellectuelle ou détient les droits d’usage sur tous les éléments accessibles sur le site, notamment les textes, images, graphismes, logo, icônes, sons, logiciels…<br />
                Toute reproduction, représentation, modification, publication, adaptation totale ou partielle des éléments du site, quel que soit le moyen ou le procédé utilisé, est interdite, sauf autorisation écrite préalable à l'email : <a href="mailto:valentinstefanescu92@gmail.com?subject=Contact ï¿½ partir des mentions lï¿½gales via le site www.ducotedemaville.fr"><strong>valentinstefanescu92@gmail.com</strong></a> .<br />
                Toute exploitation non autorisée du site ou de l’un quelconque de ces éléments qu’il contient sera considérée comme constitutive d’une contrefaçon et poursuivie conformément aux dispositions des articles L.335-2 et suivants du Code de Propriété Intellectuelle.<br />
                </p>

                <p><strong>4. Liens hypertextes et cookies :</strong><br />
                <br />
                Le site <a href="http://www.ducotedemaville.fr" rel="noreferrer noopener" target="_blank">www.ducotedemaville.fr</a> contient un certain nombre de liens hypertextes vers d’autres sites (partenaires, informations …) mis en place avec l’autorisation de le proprietaire du site . Cependant, le proprietaire du site n’a pas la possibilité de vérifier le contenu des sites ainsi visités  et décline donc toute responsabilité de ce fait quand aux risques éventuels de contenus illicites.<br />
                <br />
                L’utilisateur est informé que lors de ses visites sur le site <a href="http://www.ducotedemaville.fr" rel="noreferrer noopener" target="_blank">www.ducotedemaville.fr</a>, un ou des cookies sont susceptible de s’installer automatiquement sur son ordinateur. Un cookie est un fichier de petite taille, qui ne permet pas l’identification de l’utilisateur, mais qui enregistre des informations relatives à la navigation d’un ordinateur sur un site. Les données ainsi obtenues visent à faciliter la navigation ultérieure sur le site, et ont également vocation à permettre diverses mesures de fréquentation.<br />
                <br />
                Le paramétrage du logiciel de navigation permet d’informer de la présence de cookie et éventuellement, de refuser de la manière décrite à l’adresse suivante : www.cnil.fr<br />
                Le refus d’installation d’un cookie peut entraîner l’impossibilité d’accéder à certains services. L’utilisateur peut toutefois configurer son ordinateur de la manière suivante, pour refuser l’installation des cookies :<br />
                Sous Internet Explorer : onglet outil / options internet. Cliquez sur Confidentialité et choisissez Bloquer tous les cookies. Validez sur Ok.<br />
                Sous Netscape : onglet édition / préférences. Cliquez sur Avancées et choisissez Désactiver les cookies. Validez sur Ok.<br />
                </p>

                <p><strong>5. Protection des biens et des personnes - gestion des données personnelles :</strong><br />
                <br />
                Utilisateur : Internaute se connectant, utilisant le site susnommé : <a href="http://www.ducotedemaville.fr" rel="noreferrer noopener" target="_blank">www.ducotedemaville.fr</a><br />
                En France, les données personnelles sont notamment protégées par la loi n° 78-87 du 6 janvier 1978, la loi n° 2004-801 du 6 août 2004, l'article L. 226-13 du Code pénal et la Directive Européenne du 24 octobre 1995.</p>

                <p>Sur le site <a href="http://www.ducotedemaville.fr" rel="noreferrer noopener" target="_blank">www.ducotedemaville.fr</a>, le proprietaire du site ne collecte des informations personnelles relatives à l'utilisateur que pour le besoin de certains services proposés par le site <a href="http://www.ducotedemaville.fr" rel="noreferrer noopener" target="_blank">www.ducotedemaville.fr</a>. L'utilisateur fournit ces informations en toute connaissance de cause, notamment lorsqu'il procède par lui-même à leur saisie. Il est alors précisé à l'utilisateur du site <a href="http://www.ducotedemaville.fr" rel="noreferrer noopener" target="_blank">www.ducotedemaville.fr</a> l’obligation ou non de fournir ces informations.<br />
                Conformément aux dispositions des articles 38 et suivants de la loi 78-17 du 6 janvier 1978 relative à l’informatique, aux fichiers et aux libertés, tout utilisateur dispose d’un droit d’accès, de rectification, de suppression et d’opposition aux données personnelles le concernant. Pour l’exercer, adressez votre demande à <a href="http://www.ducotedemaville.fr" rel="noreferrer noopener" target="_blank">www.ducotedemaville.fr</a> par email : email du webmaster ou  en effectuant sa demande écrite et signée, accompagnée d’une copie du titre d’identité avec signature du titulaire de la pièce, en précisant l’adresse à laquelle la réponse doit être envoyée.</p>

                <p>Aucune information personnelle de l'utilisateur du site <a href="http://www.ducotedemaville.fr" rel="noreferrer noopener" target="_blank">www.ducotedemaville.fr</a> n'est publiée à l'insu de l'utilisateur, échangée, transférée, cédée ou vendue sur un support quelconque à des tiers. Seule l'hypothèse du rachat du site <a href="http://www.ducotedemaville.fr" rel="noreferrer noopener" target="_blank">www.ducotedemaville.fr</a> à le proprietaire du site et de ses droits permettrait la transmission des dites informations à l'éventuel acquéreur qui serait à son tour tenu de la même obligation de conservation et de modification des données vis à vis de l'utilisateur du site <a href="http://www.ducotedemaville.fr" rel="noreferrer noopener" target="_blank">www.ducotedemaville.fr</a>.</p>

                <p>Le site www.ducotedemaville.fr est en conformité avec le RGPD voir notre politique RGPD  POLITIQUE DE CONFIDENTIALITÉ ET D'UTILISATION DES DONNÉES PERSONNELLES Définition des termes utilisés dans la politique de confidentialité On désignera par la suite : ?	« Donnée personnelle » : se définit comme « toute information relative à une personne .</p>

                <p>Les bases de données sont protégées par les dispositions de la loi du 1er juillet 1998 transposant la directive 96/9 du 11 mars 1996 relative à la protection juridique des bases de données.</p>

            </div>
        </div>
    </div>
)

export default Mentions;