import React, { Component } from 'react'
import { Query } from 'react-apollo'
import { GET_SUBCATEGORY } from '../gql/request'
import Loader from './utils/Loader'
import PartnerCard from './cards/PartnerCard'
import { Helmet } from "react-helmet"
import NoData from './utils/NoData'
import { getCurrentCityHelper } from './helpers/currentCityHelper.js'

export default class SubCategory extends Component {

  state = {
    cities: [],
  }

  slugToName = (slug) => {
    return slug.replace(/-/g, ' ');
  }

  getDataChecked(data) {
    this.setState({ cities: data.cities});
  }

  render() {
    const { cities } = this.state;

    return (
      <div className="main-container subcategory-container">
        <Helmet>
          <title>Du côté de ma ville | {this.slugToName(this.props.match.params.souscategorie)}</title>
          <meta property="og:title" content={`Du côté de ma ville | ${this.slugToName(this.props.match.params.souscategorie)}`} />
          <meta property="og:url" content={window.location.href} />
        </Helmet>
        
        <Query query={GET_SUBCATEGORY} variables={{ slug: this.props.match.params.categorie, sndSlug: this.props.match.params.souscategorie, city: getCurrentCityHelper(window.location.pathname) }}>
        {({loading, error, data: { categories, villes }}) => {
          if (loading || error) return <Loader />
          const cat = categories[0]
          const subcat = cat.souscategories[0]
          if (!cat || !subcat) return <div className="no-data card"><div className="card-body">Pas de données !</div></div>

          let filteredSubcat = subcat.enseignes_list;
          if (cities.length > 0 ) {
            filteredSubcat = subcat.enseignes_list.filter(shop => cities.indexOf(shop.enseigne.ville.slug) !== -1)
          }

          return (
            <div className="row">
              <div className="col-sm-12">
                <div className="subcategory-header mb-4" style={{ backgroundImage: `url('${cat.img}')` }}>
                  <h2>{cat.nom}</h2>
                </div>

                <h5 className="mt-3 mb-0">{subcat.nom}</h5>
                <hr />
                <div className="row">
                  {filteredSubcat.length > 0 ?
                    filteredSubcat.map(data => {
                      return (
                        <div className="col-sm-12 col-md-4 col-lg-3" key={data.enseigne.slug}>
                          <PartnerCard data={data.enseigne} />
                        </div>
                      )
                    })
                    : <div className="col-sm-12"><NoData /></div>
                  }
                </div>
              </div>
            </div>
          )
        }}
        </Query>
      </div>
    );
  }
}