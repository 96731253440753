import { Link } from 'react-router-dom'
import PartnerCard from '../cards/PartnerCard'
import React from 'react'
import Slider from "react-slick"
import { uniqBy } from 'lodash'

function ThreeCardsSliderContainer({ city, category, slug, shops }) {
  const sortedShops = uniqBy(shops, 'slug');
  
  const settings = {
    dots: sortedShops.length > 3 ? true : false,
    dotsClass: 'slick-dots carousel-indicators',
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    arrows: true,
    responsive: [
      {
        breakpoint: 980,
        settings: {
          dots: sortedShops.length > 2 ? true : false,
          infinite: true,
          speed: 500,
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 0,
          arrows: false
        }
      },
      {
        breakpoint: 770,
        settings: {
          dots: sortedShops.length > 1 ? true : false,
          infinite: true,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        }
      }
    ]
  }

  if (sortedShops.length === 1) {
    sortedShops.push({})
    sortedShops.push({})
  }
  
  if (sortedShops.length === 2) {
    sortedShops.push({})
  }
       
  return (
    <div className="three-cards-slider-container">
      <h5>{category} <span><Link to={`/villes/${city}/categories/${slug}`}>Voir toutes les offres</Link></span></h5>
      <Slider {...settings}>
        {
          sortedShops.map((item, index) => {
            return (
              <div className="slider-padding" key={`${slug}-${index}`}>
                {
                  item.nom ?
                  <PartnerCard data={sortedShops[index]} promo={true} />
                  : <div></div>
                }
              </div>
            )
          })
        }
      </Slider>
    </div>
  );
}

export default ThreeCardsSliderContainer;
