import React, { Component } from 'react'

import CategoriesWithIconsBlock from './blocks/CategoriesWithIconsBlock'
import CitySlider from './sliders/CitySlider'
import { GET_FULLCITY } from '../gql/request'
import { Helmet } from 'react-helmet'
import Loader from './utils/Loader'
import NoData from './utils/NoData'
import { Query } from 'react-apollo'
import StatisticManager from './shared/StatisticManager'
import ThreeCardsSliderContainer from './sliders/ThreeCardsSliderContainer'
import { getCurrentCityHelper } from './helpers/currentCityHelper.js'

export default class City extends Component {

  constructor(props) {
    super(props)
    const { city } = props.match.params
    this.state = {
      currentCity: city,
      selectedCat: null,
    }
  }

  componentDidMount() {
    const url = window.location.toString();
    if (url.indexOf("?") > -1) {
      this.setState({ selectedCat: url.split("?")[1] });
    }
  }

  componentDidUpdate() {
    const cityUrl = getCurrentCityHelper(window.location.pathname);
    if (this.state.currentCity !== cityUrl) {
      this.setState({ currentCity: cityUrl })
    }
  }

  submitSelectedCat(categories, category) {
    if (category) {
      const findedCat = categories.find(x => x.nom === category);
      if (findedCat) {
        const url = window.location.toString();
        url.indexOf("?") > -1 ? window.location = `${url.split("?")[0]}?${findedCat.id}` : window.location = `${window.location}?${findedCat.id}`;
        this.setState({ selectedCat: findedCat.id });
      }
    } else {
      const url = window.location.toString();
      if (url.indexOf("?") > -1) {
        window.location = url.split("?")[0];
      }
      this.setState({ selectedCat: '' });
    }
  }

  goToPartner(clikedImg, images) {
    for (let img of images) {
      if (img.photo_url === clikedImg && img.is_enseigne) {
        window.location = `/villes/${this.state.currentCity}/boutiques/${img.enseigne.slug}`
      }
    }
  }

  rankShops(array) {
    const unordered = array.filter(x => x.ordre === 0);
    const orderly = array.filter(x => x.ordre > 0).sort((a, b) => a.ordre - b.ordre);
    return [...orderly, ...unordered];
  }

  shuffleShops(array) {
    return array.sort((a, b) => a.ordreAffichage - b.ordreAffichage);
  }

  render() {
    const { currentCity, selectedCat } = this.state;

    return (
      <div className="city-container main-container">
        <Helmet>
          <title>Du côté de ma ville | {currentCity}</title>
          <meta property="og:title" content={`Du côté de ma ville | ${currentCity}`} />
          <meta property="og:url" content={window.location.href} />
        </Helmet>

        <Query query={GET_FULLCITY} variables={{ filter: currentCity, cat: selectedCat && selectedCat }}>
          {({ loading, error, data }) => {
            if (loading) return <Loader />
            // Filtered: categories with "selectedCat" filter
            // All: all categories
            const { enseignes, villes, filtered, all } = data
            const categoriesWithShops = []
            const photos = []
            villes[0].photos.map(x => x.photo_url !== '' ? photos.push({ url: x.photo_url, ordreAffichage: Math.floor(Math.random() * 1000) }) : null);

            for (const shop of enseignes) {
              if (shop.souscategories.length > 0) {
                const s = {...shop, ordreAffichage: Math.floor(Math.random() * 1000)}
                for (const cat of s.souscategories) {
                  if (categoriesWithShops[cat.souscategorie.categorie.nom]) {
                    categoriesWithShops[cat.souscategorie.categorie.nom].push(s);
                    if (categoriesWithShops[cat.souscategorie.categorie.nom].filter(x => x.ordre > 0).length > 0) {
                      categoriesWithShops[cat.souscategorie.categorie.nom] = this.rankShops(categoriesWithShops[cat.souscategorie.categorie.nom]);
                    } else {
                      categoriesWithShops[cat.souscategorie.categorie.nom] = this.shuffleShops(categoriesWithShops[cat.souscategorie.categorie.nom]);
                    }
                  } else {
                    categoriesWithShops[cat.souscategorie.categorie.nom] = [s];
                  }
                }
              }
            }

            let onlyCategories = [];
            Object.keys(categoriesWithShops).map(c => onlyCategories.push(c));
            onlyCategories.sort((a, b) => {
              if (a > b) return 1;
              if (b > a) return -1;
              return 0;
            });

            const statsExist = villes[0].statistiques && villes[0].statistiques.compteur >= 0 ? true : false;
            const stats = villes[0].statistiques && villes[0].statistiques.compteur ? villes[0].statistiques.compteur : 0;

            return (
              <React.Fragment>
                <StatisticManager id={villes[0].id} stats={stats} city={true} exist={statsExist} />
                <CitySlider photos={this.shuffleShops(photos)} goToPartner={(e, imgs) => this.goToPartner(e, imgs)} villes={villes} />

                <div className="category-selector">
                  <h2>Je sélectionne une catégorie</h2>
                  <div className="row">
                    <div className="col-sm-12 col-md-6 offset-md-3 col-lg-4 offset-lg-4">
                      <select className="custom-select" onChange={e => this.submitSelectedCat(all, e.target.value)}>
                        <option defaultValue>Choisissez une catégorie</option>
                        <option key="all" value="">Toutes les catégories</option>
                        {onlyCategories.map(c => <option key={c} value={c}>{c}</option>)}
                      </select>
                    </div>
                  </div>
                </div>

                {enseignes.length > 0 ?
                  filtered.map((cat, i) => {
                    return (
                      <React.Fragment key={i}>
                        {categoriesWithShops[cat.nom] ?
                          <ThreeCardsSliderContainer
                            city={currentCity}
                            key={cat.slug}
                            slug={cat.slug}
                            category={cat.nom}
                            shops={categoriesWithShops[cat.nom]}
                          />
                          : null
                        }
                      </React.Fragment>
                    )
                  }) :
                  <div>
                    <NoData text="Aucune enseigne n'est disponible dans cette ville." />
                    <div className="mb-5"></div>
                    <CategoriesWithIconsBlock />
                  </div>
                }
              </React.Fragment>
            )
          }}
        </Query>
      </div>
    );
  }
}
