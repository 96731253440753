import React from 'react'

export default class SuggestModal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      subject: '',
      city: '',
      message: ''
    }
  }

  changeSubject(value) {
    this.setState({ subject: value });
  }

  changeCity(value) {
    this.setState({ city: value });
  }

  changeMessage(value) {
    this.setState({ message: value });
  }

  getMailTo() {
    const { subject, city, message } = this.state;
    const body = `${message} - Ville concernée : ${city}`;
    return `mailto:contact@ducotedemaville.fr?body=${body}&subject=${subject} - Site web`;
  }

  render() {
    const { subject, city, message } = this.state; 

    return (
      <div className="modal fade" id="suggestModal" tabIndex="-1" aria-labelledby="suggestModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="suggestModalLabel">Suggestion</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="container-fluid">
                <div className="row text-center align-items-center">
                  <div className="col-md-4">
                    <p><span>Vous êtes un utilisateur ?</span>Vous pouvez nous recommander une enseigne ou plus précisément le nom d'une boutique que vous souhaitez voir sur la plateforme "Du côté de ma ville". L'équipe commerciale se chargera de les contacter dans les meilleurs délais.</p>
                  </div>
                  <div className="col-md-4 align-items-center">
                    <p><span>Vous êtes une enseigne ?</span>Si vous souhaitez rejoindre notre plateforme "Du côté de ma ville" afin de promouvoir votre entreprise, n'hésitez pas à remplir le formulaire. Notre service marketing et commercial se chargeront de vous recontacter dans les plus brefs délais !</p>
                  </div>
                  <div className="col-md-4 align-items-center">
                    <p><span>Vous rencontrez un problème ?</span>Si vous rencontrez un bug ou un problème technique, il vous est possible de nous le signaler. Notre équipe de support se chargera d'en prendre connaissance et de le résoudre au plus vite.</p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12 col-md-6">
                    <div className="form-group">
                      <label htmlFor="subjectInput">Sujet *</label>
                      <select onChange={e => this.changeSubject(e.target.value)} className="form-control" id="subjectInput">
                        <option value="" defaultValue>Sélectionnez un sujet</option>
                        <option value="Proposer une enseigne">Proposer une enseigne</option>
                        <option value="Signaler un problème/bug">Signaler un problème/bug</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="form-group">
                      <label htmlFor="cityInput">Ville concernée *</label>
                      <input onChange={e => this.changeCity(e.target.value)} type="text" className="form-control" id="cityInput" placeholder="Ville et code postal" />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <label htmlFor="messageInput">Message *</label>
                      <textarea onChange={e => this.changeMessage(e.target.value)} className="form-control" id="messageInput" rows="3"></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Annuler</button>
              <a href={this.getMailTo()}>
                <button type="button" className="btn btn-primary" disabled={!subject || !message || !city }>Envoyer</button>
              </a>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

