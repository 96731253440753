import CityCard from '../cards/CityCard'
import Columns from 'react-columns'
import { GET_CITIES } from '../../gql/request'
import Loader from '../utils/Loader'
import { Query } from 'react-apollo'
import React from 'react'

var queries = [{
    columns: 2,
    query: 'min-width: 500px'
}, {
    columns: 3,
    query: 'min-width: 1000px'
}];

export default () => (
    <div className="categories-with-icons-block my-5">
        <Query query={GET_CITIES}>
        {({loading, error, data: { villes }}) => {
            if (loading || error) return <Loader />

            return (
                <div>
                    <Columns queries={queries} columns={3} gap="10px">
                    {villes.map(city => {
                        return (
                        <div style={{ marginBottom: "10px" }} key={`firstSlider-${city.slug}`}>
                            <CityCard city={city} arrows={true} />
                        </div>
                        )
                    })}
                    </Columns>
                </div>
            )
        }}
        </Query>
    </div>   
)
