import {
  INCREMENT_CATEGORY_STAT,
  INCREMENT_SHOP_STAT,
  INCREMENT_STAT,
  INSERT_CATEGORY_STAT,
  INSERT_SHOP_STAT,
  INSERT_STAT,
} from "./../../gql/request";

import { Mutation } from "react-apollo";
import React from "react";

export default class StatisticManager extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      can: true,
    };
  }

  updateStats(req) {
    setTimeout(() => {
      if (this.state.can) {
        req({
          variables: { item: this.props.id, value: this.props.stats + 1 },
        });
        this.setState({ can: false });
      }
    }, 2000);
  }

  insertStats(req) {
    setTimeout(() => {
      if (this.state.can && !this.props.exist) {
        req({ variables: { item: this.props.id, value: 1 } });
        this.setState({ can: false });
      }
    }, 2000);
  }

  render() {
    const { stats, city, category, shop, exist } = this.props;
    return (
      <>
        {city && stats && stats > 0 && exist ? (
          <Mutation mutation={INCREMENT_STAT}>
            {(update_statistiques, { data }) => (
              <button
                className="d-none"
                onClick={this.updateStats(update_statistiques)}
                type="button"
              />
            )}
          </Mutation>
        ) : null}
        {city && !exist ? (
          <Mutation mutation={INSERT_STAT}>
            {(insert_statistiques, { data }) => (
              <button
                className="d-none"
                onClick={this.insertStats(insert_statistiques)}
                type="button"
              />
            )}
          </Mutation>
        ) : null}
        {category && stats && stats > 0 && exist ? (
          <Mutation mutation={INCREMENT_CATEGORY_STAT}>
            {(update_statistiques_categorie, { data }) => (
              <button
                className="d-none"
                onClick={this.updateStats(update_statistiques_categorie)}
                type="button"
              />
            )}
          </Mutation>
        ) : null}
        {category && !exist ? (
          <Mutation mutation={INSERT_CATEGORY_STAT}>
            {(insert_statistiques_categorie, { data }) => (
              <button
                className="d-none"
                onClick={this.insertStats(insert_statistiques_categorie)}
                type="button"
              />
            )}
          </Mutation>
        ) : null}
        {shop && stats && stats > 0 && exist ? (
          <Mutation mutation={INCREMENT_SHOP_STAT}>
            {(update_statistiques_enseigne, { data }) => (
              <button
                className="d-none"
                onClick={this.updateStats(update_statistiques_enseigne)}
                type="button"
              />
            )}
          </Mutation>
        ) : null}
        {shop && !exist ? (
          <Mutation mutation={INSERT_SHOP_STAT}>
            {(insert_statistiques_enseigne, { data }) => (
              <button
                className="d-none"
                onClick={this.insertStats(insert_statistiques_enseigne)}
                type="button"
              />
            )}
          </Mutation>
        ) : null}
      </>
    );
  }
}
