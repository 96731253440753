import React from 'react'
import debounce from 'lodash'

const SearchBar = ({ onSearch, placeholder = 'Rechercher' }) => (
  <div className="searchbar-component">
    <div className="input-group">
      <div className="input-group-prepend">
        <span className="input-group-text">
          <i className="fas fa-search" />
        </span>
      </div>
      <input
        type="text"
        className="form-control"
        placeholder={placeholder}
        aria-label="Rechercher"
        aria-describedby="Rechercher"
        onChange={e => debounce(500, onSearch(e.target.value))}
      />
    </div>
  </div>
)

export default SearchBar;