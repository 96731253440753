import React, { Component } from 'react'

import CouponCard from './cards/CouponCard'
import { GET_COUPONS } from './../gql/request'
import { Helmet } from "react-helmet"
import Loader from './utils/Loader'
import NoData from './utils/NoData'
import { Query } from 'react-apollo'
import SearchBar from './utils/SearchBar'
import { getCurrentCityHelper } from './helpers/currentCityHelper.js'

export default class Coupons extends Component {

  state = {
    search: '',
    cities: [],
  }

  searchShop(value) {
    if (value.trim() !== this.state.search) {
      this.setState({ search: value.trim() });
    }
  }

  getDataChecked(data) {
    this.setState({ cities: data.cities});
  }

  render() {
    const { cities } = this.state;

    return (
      <div className="coupons-container main-container">
        <Helmet>
          <title>Du côté de ma ville | Coupons</title>
          <meta property="og:title" content="Du côté de ma ville | Coupons" />
          <meta property="og:url" content={window.location.href} />
        </Helmet>

        <SearchBar placeholder="Rechercher une offre par boutique" onSearch={(e) => this.searchShop(e)} />

        <Query query={GET_COUPONS} variables={{ search: `%${this.state.search}%`, date: new Date(), city: getCurrentCityHelper(window.location.pathname) }}>
        {({ loading, error, data: { promotions } }) => {
          if(loading || error) return <Loader />

          let filteredPromo = promotions;
          if (cities.length > 0 ) {
            filteredPromo = promotions.filter(promo => cities.indexOf(promo.enseigne.ville.slug) !== -1)
          }

          return (
            <div className="row mt-4">
              {
                filteredPromo.length > 0 ?
                filteredPromo.map((promo, index) => {
                    return (
                      <div key={index} className="col-12 col-md-4 col-xl-3">
                        <CouponCard
                          citySlug={promo.enseigne.ville.slug}
                          shopSlug={promo.enseigne.slug}
                          photo={promo.image}
                          name={promo.enseigne.nom}
                          text={promo.nom}
                          validity={promo.date_fin}
                          address={promo.enseigne.adresse}
                          logo={promo.logo}
                          creationDate={promo.date_creation}
                        />
                      </div>
                    )
                  })
                : <NoData />
              }
            </div>
          )
        }}
        </Query>
      </div>
    )
  }
}
