import { Link } from 'react-router-dom'
import React from 'react'
import appStore from './../../static/apple-store.png'
import { getCurrentCityHelper } from '../helpers/currentCityHelper.js'
import playStore from './../../static/play-store.png'

export default () => (
    <footer className="footer">
        <ul className="footer-links">
            <li>
                <Link to="/comment-ca-marche">
                    Comment ça marche
                </Link>
            </li>
            <li><span>|</span></li>
            <li>
                <Link to="/mentions-legales">
                    Mentions légales
                </Link>
            </li>
            <li><span>|</span></li>
            <li>
                <Link to="villes/v/categories">
                    Catégories
                </Link>
            </li>
            <li><span>|</span></li>
            <li>
                <Link to={`/villes/${getCurrentCityHelper(window.location.pathname, false)}/boutiques`}>
                    Boutiques A-Z
                </Link>
            </li>
            <li><span>|</span></li>
            <li>
                <Link to="/sabonner">
                    S'abonner gratuitement aux bons plans
                </Link>
            </li>
        </ul>

        <div className="divider"></div>

        <div className="follow-content">
            <a target="_blank" rel="noreferrer noopener" href="https://itunes.apple.com/app/id1449828446">
                <img src={appStore} alt="" />
            </a>

            <a target="_blank" rel="noreferrer noopener" href="https://play.google.com/store/apps/details?id=cote.ville.app.als">
                <img src={playStore} alt="" />
            </a>

            <a target="_blank" rel="noreferrer noopener" href="http://facebook.com/DuCotedemaVille/">
                <i className="fab fa-facebook" />
            </a>
        </div>
    </footer>
)
