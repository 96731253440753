import React from 'react'
import appStore from './../../static/apple-store.png'
import playStore from './../../static/play-store.png'

const DownloadAppSlide = ({ img }) => (
  <div className="download-slide">
    <div>
      <a target="_blank" rel="noreferrer noopener" href="https://itunes.apple.com/app/id1449828446">
        <img className="store" src={appStore} alt="" />
      </a>
      <a target="_blank" rel="noreferrer noopener" href="https://play.google.com/store/apps/details?id=cote.ville.app.als">
        <img className="store" src={playStore} alt="" />
      </a>
    </div>
    <img className="d-block w-100 main-img" src={img} alt="" />
  </div>
);

export default DownloadAppSlide;
