import React from 'react'
import { Link } from 'react-router-dom'

const HomeParallax = () => (
  <div className="home-parallax">
    <div className="parallax-shadow">
      <h5>Vos commerçants et offres du mois !</h5>
      <div>
        <Link to="/villes/v/partenaires-du-mois">
          <button className="btn btn-outline-light mr-2 mb-1">
            Commerçants du mois
          </button>
        </Link>
        <Link to="/villes/v/offres-du-mois">
          <button className="btn btn-outline-light mb-1">
            Offres du mois
          </button>
        </Link>
      </div>
    </div>
  </div>
);


export default HomeParallax