import React, { Component } from 'react'
import { Query } from 'react-apollo'
import CollapseItem from './utils/CollapseItem'
import Loader from './utils/Loader'
import PartnerCard from './cards/PartnerCard'
import { MONTH_SHOPS } from './../gql/request'
import SearchBar from './utils/SearchBar'
import { Helmet } from "react-helmet"
import NoData from './utils/NoData'
import { getCurrentCityHelper } from './helpers/currentCityHelper.js'

export default class MonthStores extends Component {

  state = {
    search: '',
    cities: [],
  }

  searchShop(value) {
    if (value.trim() !== this.state.search) {
      this.setState({ search: value.trim() });
    }
  }

  render() {
    const { cities } = this.state;
    
    return (
      <div className="main-container">
        <Helmet>
          <title>Du côté de ma ville | Partenaires du mois</title>
          <meta property="og:title" content="Du côté de ma ville | Partenaires du mois" />
          <meta property="og:url" content={window.location.href} />
        </Helmet>

        <SearchBar placeholder="Rechercher une boutique" onSearch={(e) => this.searchShop(e)} />

        <Query query={MONTH_SHOPS} variables={{ date: new Date(), search: `%${this.state.search}%`, city: getCurrentCityHelper(window.location.pathname) }}>
        {({loading, error, data: {villes}}) => {
          if (loading || error) return <Loader />

          const isEmpty = villes.map(ville => ville.enseignes.length > 0 ? false : true).indexOf(false);

          let filteredCities = villes;
          if (cities.length > 0 ) {
            filteredCities = villes.filter(ville => cities.indexOf(ville.slug) !== -1);
          }

          return (
            <div className="row mt-4">
              {isEmpty !== -1 ?
                <div className="accordion mb-5 w-100" id="monthStoresAccordion">
                  {filteredCities.map((ville, index) => {
                    return (
                      <React.Fragment key={ville.slug+index}>
                        {ville.enseignes.length > 0 ?
                          <CollapseItem
                            key={ville.slug}
                            id={ville.slug}
                            parent='monthStoresAccordion'
                            title={ville.nom}
                            expanded={index === 0 && "true"}
                          >
                            <div className="row">
                              {ville.enseignes.map((data, index) => {
                                return (
                                  <div className="col-12 col-md-4 col-xl-3" key={index}>
                                    <PartnerCard data={{...data, ville}} />
                                  </div>
                                )
                              })}
                            </div>
                          </CollapseItem>
                        : <NoData />
                        }
                      </React.Fragment>
                    )
                  })}
                </div>
              : <NoData />
              }
            </div>
          )

        }}
        </Query>

      </div>
    );
  }
}
