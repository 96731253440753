import React, { Component } from 'react'

import { isNewItem } from './../helpers/newItemHelper.js'

export default class LiteCouponCard extends Component {

  state = {
    expanded: false,
  }

  formattedDate(unformattedDate) {
    const date = new Date(unformattedDate);
    const d = date.getDate();
    const mm = date.getMonth()+1;
    const yyyy = date.getFullYear();

    return `${d < 10 ? '0'+d : d}/${mm < 10 ? '0'+mm : mm}/${yyyy}`;
  }

  render() {
    const { expanded } = this.state;
    const { slug, name, validity, img, condition, partConditions, id, creationDate } = this.props;
    
    return (
      <div className="card lite-coupon-card my-card mb-3">
        <div className="row">
          <div className="col-12 col-md-4">
            <img src={img} alt="" />
          </div>
          <div className="col-12 col-md-8">
            <div className="text-container">
              <h6>{name}</h6>
              <span className="date">Valable jusqu'au {this.formattedDate(validity)}</span>
              {isNewItem(creationDate) && <span className="dcdmv-badge badge bg-warning rounded-pill">Nouveau!</span>}
              <div>
                <a href={`https://images.maville-privee.fr/${id}.pdf?web=true`} target="_blank" rel="noreferrer noopener" className="print-btn">
                  imprimer mon coupon
                </a>

                {
                  (partConditions !== '' || condition !== '') &&
                  <a className="conditions" data-toggle="collapse" href={`#${slug}`} role="button" aria-expanded={expanded} aria-controls={slug} onClick={() => this.setState({ expanded: !expanded })}>
                    Conditions {expanded ? <i className="fas fa-caret-down"></i> : <i className="fas fa-caret-up"></i>}
                  </a>
                }
              </div>
            </div>
          </div>
          <div id={slug} className={expanded ? 'collapse col-12 show' : 'collapse col-12'}>
            {(partConditions && partConditions !== '') && <div style={{fontWeight: 'bold'}}>{partConditions}</div>}
            {condition ? condition : <span>Aucune condition générale pour cette offre.</span>}
          </div>
        </div>      
      </div>
    );
  }
}
