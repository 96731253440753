import React from 'react'

const ContactStoreBlock = ({ phone, mail, address, map, fixe }) => (
  <div className="card contactstore-block mb-3">
    <div className="card-title card-title-colored">
      Coordonnées
    </div>

    <div className="card-body">
      {phone ?
        <a href={`tel:+33${phone.slice(1)}`}>
          <button>
            <i className="fas fa-mobile-alt"></i>{phone}
          </button>
        </a>
      : null}

      {fixe ?
        <a href={`tel:+33${fixe.slice(1)}`}>
          <button>
            <i className="fas fa-phone"></i>{fixe}
          </button>
        </a>
      : null}

      {mail ?
        <a href={`mailto:${mail}`}>
          <button>
            <i className="fas fa-envelope"></i>Contacter par mail
          </button>
        </a>
      : null}

      <div className="address">
        <a href={`https://www.google.com/maps/place/${address}`} target="_blank" rel="noreferrer noopener">
          <div className="address-shadow">
            <span>{address}</span>
            <img src={map} alt="Adresse annonceur" />
          </div>
        </a>
      </div>
    </div>
  </div>
)

export default ContactStoreBlock;