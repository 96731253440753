import React, { Component } from 'react'

import { Link } from 'react-router-dom'
import { isNewItem } from './../helpers/newItemHelper.js'

export default class PartnerCard extends Component {

  displayPromo(promotions) {
    const sortedPromotions = promotions.sort((a, b) => new Date(b.date_creation) - new Date(a.date_creation));
    const filteredPromotions = sortedPromotions.filter(x => new Date(x.date_fin) > new Date());
    return filteredPromotions.length > 0 ? filteredPromotions[0].nom : '';
  }

  render() {
    const { data, promo } = this.props;

    return (
      <div className="card partner-card my-card mb-3">
        <Link to={`/villes/${data.ville.slug}/boutiques/${data.slug}`}>
      
          <div className="view">
            <img className="card-img-top" src={data.photo} alt={data.nom} />
          </div>
        
          <div className="card-body card-body-cascade">
            {isNewItem(data.date_creation) && <div className="text-center mb-3"><span className="dcdmv-badge badge bg-warning rounded-pill">Nouveau!</span></div>}
            <div className="row">
              <div className="col-sm-4">
                <img className="logo" src={data.logo} alt={data.nom} />
              </div>
              <div className="col-sm-8 p-0">
                <div className="card-text">{data.nom}</div>
                <span>{data.adresse}</span>
              </div>
              {
                (promo && data.promotions.length > 0) &&
                <div className="promo">
                  {this.displayPromo(data.promotions)}
                  <button>J'en profite</button>
                </div>
              }
            </div>
          </div>
      
        </Link>
      </div>
    );
  }
}