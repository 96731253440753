import DownloadAppImg from '../../static/download-1.jpeg'
import DownloadAppSlide from './DownloadAppSlide'
import React from 'react'
import Slider from 'react-slick'

const settings = {
  dots: true,
  dotsClass: 'slick-dots carousel-indicators',
  infinite: true,
  speed: 1000,
  slidesToShow: 1,
  slidesToScroll: 1,
  initialSlide: 0,
  arrows: true,
  autoplay: true,
  autoplaySpeed: 3000,
  pauseOnFocus: true,
}

function SliderShop({ imgs, video = '' }) {
  return (
    <Slider {...settings}>
      {imgs.map((img, index) => <img className="d-block w-100 slider-img" key={index} src={img} alt="" />)}
      {video && <iframe className="player d-block w-100 slider-img" title="Vidéo Youtube de l'annonceur" type="text/html" src={`https://www.youtube.com/embed/${video}`} frameBorder="0"/>}
      <DownloadAppSlide img={DownloadAppImg} />
    </Slider>
  )
}

export default SliderShop;
