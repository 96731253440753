import 'react-toastify/dist/ReactToastify.min.css'

import { ADD_SUBSCRIPTION, basicInfo } from './../gql/request'
import { Mutation, Query } from 'react-apollo'

import { Helmet } from "react-helmet"
import Loader from './utils/Loader'
import React from 'react'
import { ToastContainer } from 'react-toastify'
import { toast } from 'react-toastify'

export default class Subscription extends React.Component {

  state = {
    email: '',
    cities: [],
    rgpd: false,
  }

  onChangeEmail = (value) => {
    this.setState({ email: value });
  }

  onCheckCity = (value, checked) => {
    const { cities } = this.state;
    const newCities = [...cities];

    if (checked) {
      newCities.push({ville_id: value});
    } else {
      let pos;
      for (const [i, c] of newCities.entries()) {
        pos = c.ville_id === value ? i : null;
      }
      newCities.splice(pos+1, 1);
    }

    this.setState({ cities: newCities });
  }

  onCheckRgpd = (checked) => {
    this.setState({ rgpd: checked })
  }

  addSubscription(req) {
    const { email, cities } = this.state;
    req({ variables: { email: email, villes: cities, fromDesktop: true, creationDate: new Date() } });
    toast.info("Vous avez été ajouté à notre newsletter !");
  }

  render() {
    const { email, cities, rgpd } = this.state;

    return (
      
      <div id="abonner" className="subscription-container main-container">
        <Helmet>
          <title>Du côté de ma ville | S'abonner</title>
          <meta property="og:title" content="Du côté de ma ville | S'abonner" />
          <meta property="og:url" content={window.location.href} />
        </Helmet>

        <div className="card mb-3">
          <div className="card-header">
            <h3>S'abonner gratuitement <i className="fas fa-envelope"></i></h3>
          </div>

          <div className="card-body">
            <p>Entrez votre adresse mail pour recevoir une notification dès qu'une nouvelle promotion est publiée par un annonceur.</p>
            <form>
              <div className="input-group mb-3">
                <input onChange={e => this.onChangeEmail(e.target.value)} type="email" className="form-control" placeholder="Email" aria-label="email" />
              </div>

              <h4>Sélectionnez des villes</h4>
              <Query query={basicInfo}>
              {({loading, error, data: {villes}}) => {
                if(loading || error) return <Loader />

                return (
                  <React.Fragment>
                  {villes.map(ville => {
                    return (
                      <div className="form-check" key={ville.slug}>
                        <input onChange={e => this.onCheckCity(e.target.value, e.target.checked)} className="form-check-input" type="checkbox" value={ville.id} id={ville.id} />
                        <label className="form-check-label" htmlFor={ville.id}>{ville.nom}</label>
                      </div>
                    )
                  })}
                  </React.Fragment>
                )
              }}
              </Query>

              <div className="row mt-5">
                <div className="col-1 text-right">
                  <input onChange={e => this.onCheckRgpd(e.target.checked)} className="form-check-input" type="checkbox" value="rgpd" id="rgpd" />
                </div>
                <div className="col-11">
                  <label className="validation">
                    Si vous cochez cette case, vous acceptez et prennez en compte les mentions légales
                    misent à votre disposition dans l'application. Votre email servira uniquement à vous avertir des
                    nouvelles offres qui peuvent apparaitre dans les villes que vous avez sélectionné.
                  </label>
                </div>
              </div>

              <Mutation mutation={ADD_SUBSCRIPTION}>
                {(insert_newsletter, { data }) => (
                  <button onClick={() => this.addSubscription(insert_newsletter)} type="button" disabled={!email || cities.length < 1 || !rgpd}>Valider</button>
                )}
              </Mutation>
            </form>

            <ToastContainer />
          </div>
        </div>
      </div>
    )
  }
}