import React from 'react'

const checkUrl = (url) => {
  const pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
  return !!pattern.test(url);
}

const InfoStoreBlock = ({ name, logo, site, fb, insta, twitter, linkedin, address, visit }) => (
  <div className="card infostore-block mb-3">
    <div className="card-title card-title-colored">
      Informations
    </div>

    <div className="card-body">
      <div className="row">
        <div className="col-12 shop-title">{name}</div>
        <div className="col-6 col-md-5">
          <img className="w-100" src={logo} alt="Logo annonceur" />
        </div>
        <div className="col-6 col-md-7">
          <ul className="social-block">
            {checkUrl(fb) && <li><a target="_blank" rel="noreferrer noopener" href={fb}><i className="fab fa-facebook mr-2"></i></a></li>}
            {checkUrl(insta) && <li><a target="_blank" rel="noreferrer noopener" href={insta}><i className="fab fa-instagram mr-2"></i></a></li>}
            {checkUrl(twitter) && <li><a target="_blank" rel="noreferrer noopener" href={twitter}><i className="fab fa-twitter-square mr-2"></i></a></li>}
            {checkUrl(linkedin) && <li><a target="_blank" rel="noreferrer noopener" href={linkedin}><i className="fab fa-linkedin mr-2"></i></a></li>}
          </ul>
          <p className="info-address">{address}</p>
        </div>
      </div>

      <div className="contact-infos">
        {site &&
          <a href={site}>
            <button>
              <i className="fas fa-link"></i>Site web
            </button>
          </a>
        }
        {visit &&
          <a href={visit}>
            <button>
            <i class="fas fa-camera"></i>Visite virtuelle
            </button>
          </a>
        }
      </div>
    </div>
  </div>
)

export default InfoStoreBlock;