import React from 'react'

const CollapseItem = ({ id, parent, title, children, expanded = false }) => (
  <div className="accordion-item">
    <div className="accordion-header" id={`heading${id}`}>
      <h5 className="mb-0">
        <button className="btn btn-link" type="button" data-toggle="collapse" data-target={`#collapse${id}`} aria-expanded={expanded} aria-controls={`collapse${id}`}>
          {title}
          <i className="fas fa-chevron-down"></i>
        </button>
      </h5>
    </div>

    <div id={`collapse${id}`} className={expanded ? 'collapse show' : 'collapse'} aria-labelledby={`heading${id}`} data-parent={`#${parent}`}>
      <div className="card-body">
        {children}
      </div>
    </div>
  </div>
)

export default CollapseItem;
