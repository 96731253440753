import PartnerCard from './../cards/PartnerCard'
import React from 'react'
import Slider from 'react-slick'

const settings = {
  dots: true,
  dotsClass: 'slick-dots carousel-indicators',
  infinite: true,
  speed: 1000,
  slidesToShow: 2,
  slidesToScroll: 1,
  initialSlide: 0,
  arrows: true,
  autoplay: false,
  autoplaySpeed: 3000,
  pauseOnFocus: true,
  responsive: [
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      }
    }
  ]
}

function SimilarShopsSlider({ shops }) {
  return (
    <div className="similar-shops">
      <h6>Enseignes similaires ou complémentaires</h6>
      <Slider {...settings}>
        {shops.map((shop) => {
          return (
            <div className="pl-1 pr-1" key={shop.enseigne.id}>
              <PartnerCard data={shop.enseigne} />
            </div>
          )
        })}
      </Slider>
    </div>
  )
}

export default SimilarShopsSlider;

