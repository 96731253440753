// import DownloadApp from './Components/DownloadApp'
import CookieConsent, { Cookies } from "react-cookie-consent"
import { Route, Switch } from 'react-router-dom'

import { ApolloProvider } from 'react-apollo'
import AppDownload from "./Components/static/AppDownload"
import Categories from './Components/Categories'
import Category from './Components/Category'
import City from './Components/City'
import Coupons from './Components/Coupons'
import Footer from './Components/shared/Footer'
import Home from './Components/Home'
import How from './Components/How'
import Mentions from './Components/Mentions'
import Navbar from './Components/shared/Navbar'
import Offres from './Components/Offres'
import Partenaires from './Components/Partenaires'
import React from 'react'
import Shop from './Components/Shop'
import Shops from './Components/Shops'
import SubCategory from './Components/SubCategory'
import Subscription from './Components/Subscription'
import SuggestModal from './Components/shared/SuggestModal'
import { client } from './gql/client'

const acceptCookie = () => {
  Cookies.set("ducotedemaville_cookie", "accepted");
}

const App = ({history}) => (
  <ApolloProvider client={client}>
    <Navbar history={history} />
    <div className="app-content">
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/mentions-legales" component={Mentions} />
        <Route path="/sabonner" component={Subscription} />
        {/* <Route path="/telecharger-notre-application" component={DownloadApp} /> */}
        <Route path="/comment-ca-marche" component={How} />
        <Route exact strict path="/villes/:city/partenaires-du-mois" component={Partenaires} />
        <Route exact strict path="/villes/:city/offres-du-mois" component={Offres} />
        <Route exact strict path="/villes/:city/coupons" component={Coupons} />
        <Route exact strict path="/villes/:city/boutiques" component={Shops} />
        <Route exact strict path="/villes/:city/boutiques/:shop" component={Shop} />
        <Route exact strict path="/villes/:city/categories" component={Categories} />
        <Route exact strict path="/villes/:city/categories/:categorie" component={Category} />
        <Route exact strict path="/villes/:city/categories/:categorie/souscategorie/:souscategorie" component={SubCategory} />
        <Route exact strict path="/villes/:city" component={City} />

        {/* 404 */}
        <Route component={Home} />
      </Switch>
    </div>

    <button className="floating-btn" type="button" data-toggle="modal" data-target="#suggestModal">
      <i className="far fa-lightbulb"></i>
      <span>Suggestion</span>
    </button>

    <AppDownload />

    <CookieConsent
      location="bottom"
      buttonText="J'ai compris"
      cookieName="ducotedemaville_cookie"
      style={{ background: "#2c3e51" }}
      buttonStyle={{ color: "#ffffff", fontSize: "13px", background: "#f62dae" }}
      expires={365}
      onAccept={acceptCookie}
      acceptOnScroll={true}
    >
      En poursuivant votre navigation sur ce site, vous acceptez l’utilisation de Cookies ou autres traceurs qui permettent par exemple, de réaliser des statistiques de visites.
    </CookieConsent>

    <Footer />
    <SuggestModal />
  </ApolloProvider>
)

export default App
