import React from 'react'
import Slider from 'react-slick'
import DownloadAppSlide from './DownloadAppSlide'
import DownloadAppImg from '../../static/download-1.jpeg'

const settings = {
  dots: true,
  dotsClass: 'slick-dots carousel-indicators',
  infinite: true,
  speed: 1000,
  slidesToShow: 3,
  slidesToScroll: 3,
  initialSlide: 0,
  arrows: true,
  autoplay: true,
  autoplaySpeed: 3000,
  responsive: [
    {
      breakpoint: 980,
      settings: {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 0,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 3000,
      }
    },
    {
      breakpoint: 770,
      settings: {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 3000,
      }
    }
  ]
}

function CitySlider({ photos, goToPartner, villes }) {
  return (
    <div className="city-header">
      <Slider {...settings}>
        {
          photos.map(photo => {
            return (
              <div onClick={() => goToPartner(photo.url, villes[0].photos)} key={photo.url} className="p-1">   
                <img src={photo.url} alt="" className="slider-img" />
              </div>
            )
          })
        }
        <DownloadAppSlide img={DownloadAppImg} />
      </Slider>
    </div>
  )
}

export default CitySlider;

