import { Link } from 'react-router-dom'
import React from 'react'

const HomeText = () => (
  <div className="row mr-0 ml-0 home-text">
    <div className="col-sm-12 col-md-4">
      <i className="fas fa-euro-sign"></i>
      <h6>Offres du mois</h6>
      <p>Retrouvez une sélection des meilleurs promotions du mois. Il vous sera possible de télécharger ou imprimer les coupons afin de bénéficier des remises.</p>
    </div>
    <div className="col-sm-12 col-md-4">
      <i className="fas fa-store"></i>
      <h6>Partenaires du mois</h6>
      <p>Retrouvez une sélection des meilleurs partenaires du mois. Il vous sera possible de les retrouver par villes, par catégories ou par sous-catégories.</p>
    </div>
    <div className="col-sm-12 col-md-4">
      <i className="fas fa-question-circle"></i>
      <Link to={`/sabonner`}>
        <h6>S'abonner gratuitement aux bons plans</h6>
      </Link>
      <p>Abonnez vous pour recevoir régulièrement des bons plans sur votre ville.</p>
    </div>
  </div>
);


export default HomeText