import React from 'react'

const NoData = ({text = "Aucun résultat ne correspond à votre recherche."}) => (
  <div className="no-data mb-3">
    <i className="fas fa-info-circle"></i>
    {text}
  </div>
)

export default NoData
