import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Query } from 'react-apollo'
import Loader from './utils/Loader'
import { basicInfo } from '../gql/request'
import { Helmet } from "react-helmet"

export default class Categories extends Component {
  render() {    
    return (
      <div className="categories-container main-container">
        <Helmet>
          <title>Du côté de ma ville | Catégories</title>
          <meta property="og:title" content="Du côté de ma ville | Catégories" />
          <meta property="og:url" content={window.location.href} />
        </Helmet>

        <Query query={basicInfo}>
        {({loading, error, data: {categories, villes}}) => {
          if (loading || error) return <Loader />

          return (
            <div className="row">
              <div className="col-12">
                <div className="card mb-3">
                {categories.map(categorie => {
                  let nom = categorie.nom
                  let slug = categorie.slug
                  let souscategories = categorie.souscategories

                  return (
                    <div key={categorie.id}>
                      <h4><Link to={`/villes/v/categories/${slug}`}>{nom}</Link></h4>
                      <ul className="list-divider">
                        {souscategories.map(sc => {
                          let nomSousCat = sc.nom
                          let slugSousCat = sc.slug

                          return (
                            <li key={sc.id}>
                              <Link to={`/villes/v/categories/${slug}/souscategorie/${slugSousCat}`}>
                                {nomSousCat}
                              </Link>
                            </li>
                          )
                        })}
                      </ul>
                    </div>
                  )
                })}
                </div>
              </div>
            </div>
          )
        }}
        </Query>
      </div>
    );
  }
}