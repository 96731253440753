import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Slider from 'react-slick'

const settings = {
  infinite: true,
  speed: 1000,
  initialSlide: 0,
  arrows: false,
  autoplay: true,
  autoplaySpeed: 3000,
}

export default class CityCard extends Component {
  render() {
    const { city } = this.props;

    return (
      <Link to={`/villes/${city.slug}`}>
        <Slider {...settings}>
          {
            city.photos.filter(x => !x.is_enseigne).map((photo, index) => {
              return (
                <div className={`carousel-item ${index === 0 ? 'active' : ''}`} key={index}>
                  <h6 className="city-title">{city.nom}</h6>
                  <img className="d-block w-100 card-size " src={photo.photo_url} alt={photo.photo_url} />
                </div>
              )
            })
          }
        </Slider>
      </Link>
    )
  }
}
