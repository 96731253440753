import React, { Component } from 'react'

import CollapseItem from './utils/CollapseItem'
import CouponCard from './cards/CouponCard'
import { Helmet } from "react-helmet"
import Loader from './utils/Loader'
import { MONTH_OFFERS } from './../gql/request'
import NoData from './utils/NoData'
import { Query } from 'react-apollo'
import SearchBar from './utils/SearchBar'
import { getCurrentCityHelper } from './helpers/currentCityHelper.js'

export default class MonthPromotions extends Component {

  state = {
    search: '',
    cities: [],
  }

  searchShop(value) {
    if (value.trim() !== this.state.search) {
      this.setState({ search: value.trim() });
    }
  }

  getDataChecked(data) {
    this.setState({ cities: data.cities});
  }

  render() {
    const { cities } = this.state;

	  return (
      <div className="main-container">
        <Helmet>
          <title>Du côté de ma ville | Offres du mois</title>
          <meta property="og:title" content="Du côté de ma ville | Offres du mois" />
          <meta property="og:url" content={window.location.href} />
        </Helmet>

        <SearchBar placeholder="Rechercher une offre par boutique" onSearch={(e) => this.searchShop(e)} />

        <Query query={MONTH_OFFERS} variables={{ date: new Date(), search: `%${this.state.search}%`, city: getCurrentCityHelper(window.location.pathname) }}>
        {({loading, error, data: {villes}}) => {
          if (loading || error) return <Loader />

          let filteredCities = villes;
          if (cities.length > 0 ) {
            filteredCities = villes.filter(ville => cities.indexOf(ville.slug) !== -1)
          }

          return (
            <div className="row mt-4">
              <div className="accordion mb-5 w-100" id="monthPromotionsAccordion">
                {filteredCities.map((ville, index) => {
                  return (
                    <React.Fragment key={ville.slug+index}>
                      {ville.enseignes.length > 0 ?
                        <CollapseItem
                          key={index}
                          id={ville.slug}
                          parent='monthPromotionsAccordion'
                          title={ville.nom}
                          expanded={index === 0 && "true"}
                        >
                          <div className="row">
                            {ville.enseignes.map((enseigne, idx) => {
                              return enseigne.promotions.map((data, ind) => {
                                return (
                                  <div className="col-12 col-md-4 col-xl-3" key={`${enseigne.slug}-${idx}${ind}`}>
                                    <CouponCard
                                      citySlug={ville.slug}
                                      shopSlug={data.enseigne.slug}
                                      photo={data.image}
                                      name={enseigne.nom}
                                      text={data.nom}
                                      validity={data.date_fin}
                                      address={data.enseigne.adresse}
                                      logo={data.enseigne.logo}
                                      creationDate={data.date_creation}
                                    />
                                  </div>
                                )
                              })
                            })}
                          </div>
                        </CollapseItem>
                      : <NoData /> }
                    </React.Fragment>
                  )
                })}
              </div>
            </div>
          )

        }} 
        </Query>

      </div>
	  );
  }
}
