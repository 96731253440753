import React, { Component } from 'react'

import CouponCard from './cards/CouponCard'
import { GET_CATEGORY } from '../gql/request'
import { Helmet } from "react-helmet"
import Loader from './utils/Loader'
import { Query } from 'react-apollo'
import { Redirect } from 'react-router-dom'
import StatisticManager from './shared/StatisticManager'
import { getCurrentCityHelper } from './helpers/currentCityHelper.js'

export default class Category extends Component {

  constructor(props) {
    super(props)
    const { categorie, city } = props.match.params
    this.state = {
      currentCategory: categorie,
      currentCity: city,
      cities: city ? [city] : [],
    }
  }

  componentDidUpdate() {
    const cityUrl = getCurrentCityHelper(window.location.pathname);
    if (this.state.currentCity !== cityUrl) {
      this.setState({ currentCity: cityUrl })
    }
  }

  slugToName = (slug) => {
    return slug.replace(/-/g, ' ');
  }

  checkSubcategories(shop, id) {
    let canShow = false;
    if (shop.souscategorie !== null && shop.souscategorie.categorie.id === id) {
      canShow = true;
    }
    if (shop.souscategories && shop.souscategories.length > 0) {
      const arr = shop.souscategories.filter(x => x.souscategorie.categorie_id === id);
      if (arr.length > 0) {
        canShow = true;
      }
    }
    return canShow;
  }

  render() {
    const { currentCity } = this.state;
    const { categorie } = this.props.match.params;

    return (
      <div className="main-container category-container">
        <Helmet>
          <title>Du côté de ma ville | {this.slugToName(categorie)}</title>
          <meta property="og:title" content={`Du côté de ma ville | ${this.slugToName(categorie)}`} />
          <meta property="og:url" content={window.location.href} />
        </Helmet>

        <Query query={GET_CATEGORY} variables={{ filter: categorie, city: currentCity }}>
        {({loading, error, data: { categories, promotions }}) => {
          if (loading || error) return <Loader />
          const cat = categories[0];
          if (!cat) return <Redirect to="/" />
          const statsExist = cat.statistiques && cat.statistiques.compteur >= 0 ? true : false;
          const stats = cat.statistiques && cat.statistiques.compteur ? cat.statistiques.compteur : 0;

          return (
            <div className="row">
              <div className="col-sm-12">
                <StatisticManager id={cat.id} stats={stats} category={true} exist={statsExist} />
                <div className="category-header mb-4" style={{ backgroundImage: `url('${cat.img}')` }}>
                  <h2>{cat.nom}</h2>
                </div>

                {(currentCity === '%%' || currentCity === 'v') && <p>Veuillez choisir une ville dans le menu afin que nous puissions vous afficher les catégories correspondantes.</p>}

                <div className="row">
                  {promotions.filter(x => new Date(x.date_fin) > new Date()).map(promo => {
                    return (
                      <React.Fragment key={promo.id}>
                        {this.checkSubcategories(promo.enseigne, cat.id) ?
                          <div className="col-sm-12 col-md-6 col-lg-4" key={promo.id}>
                            <CouponCard
                              citySlug={promo.enseigne.ville.slug}
                              shopSlug={promo.enseigne.slug}
                              photo={promo.image}
                              name={promo.enseigne.nom}
                              text={promo.nom}
                              validity={promo.date_fin}
                              address={promo.enseigne.adresse}
                              logo={promo.logo}
                              creationDate={promo.date_creation}
                            />
                          </div>
                        : null}
                      </React.Fragment>
                    )
                  })}
                </div>
              </div>
            </div>
          )
        }}
        </Query>
      </div>
    );
  }
}
