import { GET_CITIES_NAME, SEARCH_SHOP_CATEGORIES_SUBCATEGORIES } from './../../gql/request'
import { Link, Redirect } from 'react-router-dom'

import NavSearch from './NavSearch'
import { Query } from 'react-apollo'
import React from 'react'
import debounce from 'lodash'
import logo from '../../static/logo.png'

export default class Navbar extends React.Component {

  constructor(props) {
    super(props);
    this.searchInputRef = React.createRef();
    this.state = {
      url: '',
      search: '',
      selectedCity: 'v',
      mustBeRedirect: false
    }
  }
  
  componentDidMount() {
    const splited = window.location.pathname.split('/')
    const index = splited.indexOf('villes')
    const city = index > -1 ? splited[index + 1] : 'v';
    this.setState({ url: window.location.pathname, search: '', selectedCity: city })
  }

  componentDidUpdate() {
    if (this.state.url !== window.location.pathname && this.state.url !== '' && window.location.pathname.indexOf('villes') > -1) {
      const splited = window.location.pathname.split('/')
      const index = splited.indexOf('villes')
      const city = index > -1 ? splited[index + 1] : 'v';
      this.setState({ url: window.location.pathname, search: '', selectedCity: city })
    }
  }

  changeUrl() {
    // document.getElementsByClassName("navbar-toggler")[0].className = 'navbar-toggler collapsed'
    // document.getElementById("navbarToggler").classList.remove("show")

    if (this.state.url !== window.location.pathname) {
      this.setState({ url: window.location.pathname, mustBeRedirect: false })
    }
  }

  onSearch(value) {
    if (value.trim() !== this.state.search) {
      this.setState({ search: value.trim() });
    }
  }

  onSearchResultClick(type, data) {
    this.closeSearch();
    switch(type) {
      case 'shop':
        this.setState({ url: `/villes/${data.ville.slug}/boutiques/${data.slug}` });
        break;
      case 'cat':
        this.setState({ url: `/villes/${this.selectedCity}/categories/${data.slug}` });
        break;
      case 'sc':
        this.setState({ url: `/villes/${this.selectedCity}/categories/${data.categorie.slug}/souscategorie/${data.slug}` });
        break;
      default:
        this.setState({ url: `/` });
        break
    } 
  }

  closeSearch() {
    this.setState({ search: '' });
    this.searchInputRef.value = "";
  }

  selectCity(city) {
    let newUrl;
    if (window.location.pathname.indexOf('/boutiques/') > -1) {
      newUrl = `/villes/${city}`;
    } else {
      newUrl = this.state.url.replace(`villes/${this.state.selectedCity}`, `villes/${city}`);
    }
    this.setState({ selectedCity: city, url: newUrl, mustBeRedirect: true });
  }

  render() {
    const { url, search, selectedCity, mustBeRedirect } = this.state;

    if (url !== window.location.pathname && url !== '') {
      this.changeUrl()
    }

    if (mustBeRedirect && url !== window.location.pathname) return <Redirect to={url} />

    return (
      <div className="pos-f-t">
        <nav className="navbar fixed-top navbar-expand-lg navbar-light">
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarToggler"
            aria-controls="navbarToggler"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
  
          <Link to="/" className="navbar-brand-container">
            <img className="navbar-brand" alt="Logo" src={logo} />
          </Link>

          <div className="search mobile d-lg-none">
            <div className="input-group mb-3">
              <input onChange={e => debounce(500, this.onSearch(e.target.value))} ref={el => this.searchInputRef = el} type="text" className="form-control" placeholder="Recherche..." aria-label="Recherche" aria-describedby="search-addon" />
              <div className="input-group-append">
                <span className="input-group-text" id="search-addon">
                  <i className="fas fa-search"></i>
                </span>
              </div>
              
              <Query query={SEARCH_SHOP_CATEGORIES_SUBCATEGORIES} variables={{ search: `%${search}%` }}>
                {({loading, error, data: { enseignes, shopsWithSubcategories, souscategories }}) => {
                  if (loading || error) return null

                  return (
                    <React.Fragment>
                      {search ?
                        <NavSearch
                          shops={[...enseignes.slice(0, 5), ...shopsWithSubcategories]}
                          subcats={souscategories}
                          search={(t, d) => this.onSearchResultClick(t, d)}
                          close={() => this.closeSearch()}
                          isMobile={true}
                        /> : null}
                    </React.Fragment>
                  )
                }}
              </Query>
            </div>
          </div>
  
          <div className="collapse navbar-collapse pl-3 pr-3" id="navbarToggler">
            <Query query={GET_CITIES_NAME}>
              {({loading, error, data: { villes }}) => {
                if (loading || error) return null
                
                return (
                  <select className={url !== '/' ? 'navbar-select' : 'd-none'} value={selectedCity} onChange={e => this.selectCity(e.target.value)}>
                    {selectedCity === 'v' && <option defaultValue>Choisissez une ville</option>}
                    {villes.map(ville => <option key={ville.slug} value={ville.slug}>{ville.nom}</option>)}
                  </select>
                )
              }}
            </Query>

            <ul className="ml-auto links-navbar">
              {(selectedCity && selectedCity !== 'v') && <li><Link to={`/villes/${selectedCity}`}>Ma ville</Link></li>}
              <li><Link to={`/villes/${selectedCity}/coupons`}>Coupons</Link></li>
              <li><Link to={`/villes/${selectedCity}/partenaires-du-mois`}>Partenaires du mois</Link></li>
              <li><Link to={`/villes/${selectedCity}/offres-du-mois`}>Offres du mois</Link></li>
            </ul>

            <div className="search d-none d-lg-block">
              <div className="input-group mb-3">
                <input onChange={e => debounce(500, this.onSearch(e.target.value))} ref={el => this.searchInputRef = el} type="text" className="form-control" placeholder="Recherche..." aria-label="Recherche" aria-describedby="search-addon" />
                <div className="input-group-append">
                  <span className="input-group-text" id="search-addon">
                    <i className="fas fa-search"></i>
                  </span>
                </div>
                
                <Query query={SEARCH_SHOP_CATEGORIES_SUBCATEGORIES} variables={{ search: `%${search}%` }}>
                  {({loading, error, data: { enseignes, shopsWithSubcategories, souscategories }}) => {
                    if (loading || error) return null
                    
                    return (
                      <React.Fragment>
                        {search ?
                          <NavSearch
                            shops={[...enseignes, ...shopsWithSubcategories]}
                            subcats={souscategories}
                            search={(t, d) => this.onSearchResultClick(t, d)}
                            close={() => this.closeSearch()}
                          /> : null}
                      </React.Fragment>
                    )
                  }}
                </Query>
              </div>
            </div>
          </div>
        </nav>
      </div>
    )
  }
}