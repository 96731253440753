import React, { Component } from 'react'
import { Query } from 'react-apollo'
import { GET_CATEGORIES_WITH_ICONS } from '../../gql/request'
import { Link } from 'react-router-dom'
import Loader from '../utils/Loader'

export default class CategoriesWithIconsBlock extends Component {
  render() {
    return (
      <div className="categories-with-icons-block">
        <h3>Nos catégories</h3>

        <Query query={GET_CATEGORIES_WITH_ICONS}>
        {({loading, error, data: { categories }}) => {
          if (loading || error) return <Loader />

          return (
            <div className="categories-home">
              {categories.map(cat => {
                return (
                  <div className="gradient-card-header" style={{backgroundImage: `url(" ${cat.img}")`}} key={cat.slug}>
                    <div className="shadow-view">
                      <Link to={`/villes/v/categories/${cat.slug}`}>
                        <div>
                          <h2 className="card-header-title mb-3">{cat.nom}</h2>
                          {cat.souscategories.length > 1 ?
                          <p className="card-header-subtitle mb-0">{cat.souscategories.length} sous catégories</p>
                          : <p className="card-header-subtitle mb-0">{cat.souscategories.length} sous catégorie</p>
                          }
                        </div>
                      </Link>
                    </div>
                  </div>
                )
              })}
            </div>
          )
        }}
        </Query>
      </div>
    )
  }
}
