import DownloadAppImg4 from './../../static/download-4.PNG'
import React from 'react'

function DownloadAppBlock() {
  return (
    <div className="card download-app-card">
      <img src={DownloadAppImg4} alt="Téléchargez l'application" />
    </div>
  )
}

export default DownloadAppBlock;
