import { Link } from 'react-router-dom'
import React from 'react'

export default ({shops, subcats, search, close, isMobile = false}) => (
  <div className="search-results">
    <ul className="nav nav-tabs" id="searchTabs" role="tablist">
      <li className="nav-item ml-2">
        <a className="nav-link active" id={isMobile ? 'shops-tab-mobile' : 'shops-tab'} data-toggle="tab" href={isMobile ? '#shops-mobile' : '#shops'} role="tab" aria-controls={isMobile ? 'shops-mobile' : 'shops'} aria-selected="true">Boutiques</a>
      </li>
      <li className="nav-item">
        <a className="nav-link" id={isMobile ? 'subcat-tab-mobile' : 'subcat-tab'} data-toggle="tab" href={isMobile ? '#subcat-mobile' : '#subcat'} role="tab" aria-controls={isMobile ? 'subcat-mobile' : 'shops'} aria-selected="false">Catégories détaillées</a>
      </li>
    </ul>
    <div className="tab-content" id="searchTabsContent">
      <div className="tab-pane fade show active" id={isMobile ? 'shops-mobile' : 'shops'} role="tabpanel" aria-labelledby={isMobile ? 'shops-tab-mobile' : 'shops-tab'}>
        {shops && shops.length > 0 ?
          <ul className="results">
            {shops.map(e => <li key={e.slug} onClick={() => search('shop', e)}><Link to={`/villes/${e.ville.slug}/boutiques/${e.slug}`}>{e.nom} <span className="city-name">à {e.ville.nom}</span></Link></li>)}
          </ul>
        : <div className="text-center">Aucun résultat ne correspond à votre recherche...</div>}
      </div>
      <div className="tab-pane fade" id={isMobile ? 'subcat-mobile' : 'subcat'} role="tabpanel" aria-labelledby={isMobile ? 'subcat-tab-mobile' : 'subcat-tab'}>
        {subcats && subcats.length > 0 ?
          <ul className="results">
            {subcats.map(s => <li key={s.slug} onClick={() => search('sc', s)}><Link to={`/villes/v/categories/${s.categorie.slug}/souscategorie/${s.slug}`}>{s.nom}</Link></li>)}
          </ul>
        : <div className="text-center">Aucun résultat ne correspond à votre recherche...</div>}
      </div>
    </div>
    <div onClick={close} className="search-close">fermer la recherche</div>
  </div>
)
