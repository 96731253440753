import gql from 'graphql-tag'

export const GET_CITIES_NAME = gql`
    query {
        villes(order_by: {nom: asc}) {
            slug
            nom
        }
    }
`

export const GET_CITIES = gql`
  query {
    villes(order_by: {nom: asc}) {
      id
      slug
      nom
      photos {
        photo_url
        is_enseigne
      }
    }
  }
`

export const GET_SHOPS = gql`
    query getShops($search: String!, $city: String) {
        enseignes(where: {nom: {_ilike: $search}, _and: {ville: {slug: {_ilike: $city}}}}, order_by: {nom: asc}) {
            id
            nom
            description
            adresse
            email
            facebook
            instagram
            twitter
            site
            logo
            photo
            slug
            telephone
            conditions
            date_creation
            ville {
                nom
                slug
            }
            souscategorie {
                nom
            }
        }
    }
`

export const GET_COUPONS = gql`
    query getCoupons($search: String!, $date: timestamptz!, $city: String) {
        promotions(where: {nom: {_ilike: $search}, _and: {date_fin: {_gte: $date}}, enseigne: {ville: {slug: {_ilike: $city}}}}, order_by: {date_creation: desc}) {
            coupon
            date_fin
            date_creation
            conditions_particulieres
            condition
            image
            logo
            nom
            id
            slug
            enseigne {
                nom
                slug
                adresse
                ville {
                    slug
                }
            }
        }
    }
`

export const basicInfo = gql`
    query {
        categories(order_by: {nom: asc}) {
            id
            nom
            slug
            img
            souscategories(order_by: {nom: asc}) {
                id
                nom
                slug
                enseignes {
                    ville {
                        nom
                        slug
                    }
                }
            }
        }
        villes(order_by: {nom: asc}) {
            id
            nom
            logo
            slug
        }
    }
`

export const categories = gql`
    query {
        categories(order_by: {nom: asc}) {
            id
            nom
            slug
            souscategories(order_by: {nom: asc}) {
                id
                nom
                slug
            }
        }
    }
`

export const GET_CATEGORIES_WITH_ICONS = gql`
    query {
        categories(order_by: {nom: asc}) {
            id
            nom
            slug
            icon
            img
            souscategories {
                nom
                slug
            }
        }
    }
`

export const villeSearch = gql`
    query VillesSearch($filter: String!) {
        villes(where: {nom: {_like: $filter}}) {
            id
            nom
            logo
            photos {
                photo_url
                is_enseigne
                enseigne {
                    nom
                    slug
                }
            }
            slug
        }
    }
`

export const GET_CITY = gql`
    query getCity($filter: String!) {
        categories_promotions(where: {villeslug: {_eq: $filter}}, order_by: {categoriesnom: asc}) {
            categoriesnom
            categoriesslug
            nom
            slug
            description
            date_fin
            enseignes {
                logo
                photo
                slug
                nom
                adresse
                email
                telephone
            }
        }
        villes(where: {slug: {_eq: $filter}}) {
            nom
            slug
            photos {
                photo_url
                is_enseigne
                enseigne {
                    nom
                    slug
                }
            }
        }
    }
`

export const GET_FULLCITY = gql`
    query getFullCity($filter: String!, $cat: uuid) {
        enseignes(where: {ville: {slug: {_eq: $filter}}}) {
            nom
            adresse
            logo
            slug
            photo
            ordre
            date_creation
            ville {
                nom
                slug
            } 
            souscategories {
              souscategorie_id
              souscategorie {
                categorie_id
                categorie {
                  nom
                }
              }
            }
            promotions {
                date_fin
                date_creation
                slug
                id
                nom
            }
        }
        villes(where: {slug: {_eq: $filter}}) {
            nom
            slug
            id
            photos {
                photo_url
                is_enseigne
                enseigne {
                    nom
                    slug
                }
            }
            statistiques {
                compteur
            }
        }
        filtered: categories(where: {id: {_eq: $cat}}, order_by: {nom: asc}) {
            nom
            slug
            id
        }
        all: categories {
            nom
            slug
            id
        }
    }
`

export const promotionByVilleAndPromoSlug = gql`
    query Promotions($ville: String!, $promotionslug: String!) {
        categories_promotions(where:{
            villeslug: { _eq: $ville },
            _and: { slug: { _eq: $promotionslug } }
        }) {
          nom
          slug
          villenom
          code
          description
        }
    }
`

export const enseignes = gql`
    query {
        enseignes(order_by: { nom: asc }) {
            nom
            logo
            slug
            adresse
            site
            facebook
            twitter
        }
        villes(order_by: { nom: asc }) {
            nom
            slug
        }
        categories(order_by: { nom: asc }) {
            nom
            slug
        }
    }
`

export const CITY_AND_SHOPS = gql`
    query getCityAndShops($search: String!) {
        villes(order_by: { nom: asc }) {
            nom
            slug
            enseignes(where: {nom: {_ilike: $search}}, order_by: {nom: asc}) {
                nom
                slug
                adresse
                email
                logo
                twitter
                facebook
                instagram
                telephone
                description
                photo
                promotions {
                    date_fin
                    conditions_particulieres
                    condition
                    logo
                    pdf
                    image
                    nom
                }
            }
        }
    }
`

export const GET_SHOP = gql`
    query GetShop($shop: String!, $date: timestamptz!) {
        enseignes(where: {slug: {_eq: $shop}}) {
            nom
            adresse
            conditions
            description
            email
            facebook
            id
            instagram
            linkedin
            twitter
            telephone
            fixe
            video
            visit
            site
            logo
            photo
            photo_2
            photo_3
            slug
            promotions(where: {date_fin: {_gte: $date}}, order_by: {date_creation: desc}) {
                code
                condition
                coupon
                date_fin
                date_creation
                conditions_particulieres
                image
                slug
                logo
                id
                nom
            }
            souscategories {
                souscategorie_id
                souscategorie {
                    nom
                    slug
                    categorie_id
                    categorie {
                        nom
                        slug
                    }
                }
            }
            enseignes_similaire {
                ordre
                enseigne {
                  nom
                  id
                  slug
                  photo
                  date_creation
                  logo
                  adresse
                  ville {
                    slug
                  }
                }
            }
            ville {
                nom
                slug
                map
            }
            statistiques {
                compteur
            }
        }
    }
`

export const GET_CATEGORY = gql`
    query getCategory($filter: String!, $city: String) {
        categories(where: {slug: {_eq: $filter}}) {
            id
            img
            nom
            slug
            souscategories {
                nom
                id
                slug
            }
            statistiques {
                compteur
            }
        }
        promotions(where: {enseigne: {ville: {slug: {_eq: $city}}}}) {
            image
            nom
            date_fin
            logo
            id
            date_creation
            enseigne {
                slug
                nom
                adresse
                ville {
                    nom
                    slug
                }
                souscategories {
                  souscategorie_id
                  souscategorie {
                    nom
                    categorie_id
                    categorie {
                      nom
                      id
                    }
                  }
                }
                souscategorie {
                  nom
                  categorie {
                    nom
                    id
                  }
                }
            }
        }
    }
`

export const GET_SUBCATEGORY = gql`
    query getSubCategory($slug: String!, $sndSlug: String!, $city: String) {
        categories(where: {slug: {_eq: $slug}}) {
            nom
            slug
            id
            img
            souscategories(where: {slug: {_eq: $sndSlug}}) {
                nom
                id
                slug
                enseignes_list(where: {enseigne: {ville: {slug: {_ilike: $city}}}}) {
                    enseigne {
                        nom
                        adresse
                        id
                        slug
                        logo
                        date_creation
                        photo
                        ville {
                            slug
                        }
                    }
                }
            }
        }
        villes(order_by: { nom: asc }) {
            nom
            slug
        }
    }
`

export const ADD_SUBSCRIPTION = gql`
    mutation insert_newsletter($email: String!, $villes: [newsletter_ville_insert_input], $fromDesktop: Boolean, $creationDate: timestamptz!) {
        insert_newsletter(objects: {fromDesktop: $fromDesktop, email: $email, villes: {data: $villes}, date_creation: $creationDate}) {
            affected_rows
        }
    }
`

export const SEARCH_SHOP_CATEGORIES_SUBCATEGORIES = gql`
    query searchShop($search: String!) {
        enseignes(where: {nom: {_ilike: $search}}, limit: 10) {
            nom
            slug
            id
            ville {
                slug
                nom
            }
        }
        shopsWithSubcategories: enseignes(where: {souscategories: {souscategorie: {nom: {_ilike: $search}}}}, limit: 5) {
            nom
            slug
            id
            ville {
                slug
                nom
            }
        }
        souscategories(where: {nom: {_ilike: $search}}, limit: 10) {
            nom
            slug
            id
            categorie {
                nom
                slug
                id
            }
        }
    }
`

export const MONTH_SHOPS = gql`
    query getMonthShops($date: timestamptz!, $search: String!, $city: String) {
        villes(where: {slug: {_ilike: $city}}) {
            nom
            slug
            id
            enseignes(where: {date_fin_premium: {_gte: $date}, _and: {nom: {_ilike: $search}}}, order_by: {date_creation: desc}) {
                id
                nom
                logo
                photo
                adresse
                slug
                date_creation
            }
        }
    }
`

export const MONTH_OFFERS = gql`
    query getMonthOffers($date: timestamptz!, $search: String!, $city: String) {
        villes(where: {slug: {_ilike: $city}}) {
            nom
            slug
            id
            enseignes {
                promotions(where: {date_fin: {_gte: $date}, _and: {date_fin_premium: {_gte: $date}, _and: {enseigne: {nom: {_ilike: $search}}}}}, order_by: {date_creation: desc}) {
                    nom
                    image
                    date_fin
                    date_creation
                    coupon
                    condition
                    conditions_particulieres
                    id
                    enseigne {
                        nom
                        id
                        adresse
                        logo
                        ville_id
                        slug
                    }
                }
            }
        }
    }
`

export const INCREMENT_STAT = gql`
    mutation incrementStat($item: uuid, $value: Int) {
        update_statistiques(where: {stat_id: {_eq: $item}}, _set: {compteur: $value}) {
            affected_rows
        }
    }
`

export const INSERT_STAT = gql`
    mutation insertStat($item: uuid, $value: Int) {
        insert_statistiques(objects: {compteur: $value, stat_id: $item}) {
            affected_rows
        }
    }
`

export const INCREMENT_CATEGORY_STAT = gql`
    mutation incrementCategoryStat($item: uuid, $value: Int) {
        update_statistiques_categorie(where: {stat_id: {_eq: $item}}, _set: {compteur: $value}) {
            affected_rows
        }
    }
`

export const INSERT_CATEGORY_STAT = gql`
    mutation insertCategoryStat($item: uuid, $value: Int) {
        insert_statistiques_categorie(objects: {compteur: $value, stat_id: $item}) {
            affected_rows
        }
    }
`

export const INCREMENT_SHOP_STAT = gql`
    mutation incrementShopStat($item: uuid, $value: Int) {
        update_statistiques_enseigne(where: {stat_id: {_eq: $item}}, _set: {compteur: $value}) {
            affected_rows
        }
    }
`

export const INSERT_SHOP_STAT = gql`
    mutation insertShopStat($item: uuid, $value: Int) {
        insert_statistiques_enseigne(objects: {compteur: $value, stat_id: $item}) {
            affected_rows
        }
    }
`
