import React, { Component } from 'react'
import { Query } from 'react-apollo'
import { Redirect } from 'react-router'
import { GET_CITIES_NAME } from '../../gql/request'
import Loader from '../utils/Loader'

export default class SelectCityBlock extends Component {

  state = { selectedCity: '', redirect: false }

  onRedirect = (city) => {
    if (city !== '') {
      this.setState({ selectedCity: city, redirect: true });
    }
  }

  renderRedirect = () => {
    if (this.state.redirect) {
      return <Redirect push to={`/villes/${this.state.selectedCity}`} />
    }
  }

  render() {
    return (
      <div className="select-city">
        {this.renderRedirect()}
        <h1>Les meilleurs commerces et services sont du côté de ma ville</h1>

        <div className="input-group">
          <Query query={GET_CITIES_NAME}>
          {({loading, error, data: { villes }}) => {
            if (loading || error) return <Loader />

            return (
              <select className="custom-select" onChange={e => this.onRedirect(e.target.value)}>
                <option defaultValue>Choisissez votre ville</option>
                {villes.map(ville => <option key={ville.slug} value={ville.slug}>{ville.nom}</option>)}
              </select>
            )
          }}
          </Query>
        </div>
      </div>
    )
  }
}
