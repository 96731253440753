import React, { Component } from 'react'

import Breadcrumbs from './utils/Breadcrumbs'
import ContactStoreBlock from './blocks/ContactStoreBlock'
import DownloadAppBlock from './blocks/DownloadAppBlock'
import {GET_SHOP} from './../gql/request'
import { Helmet } from "react-helmet"
import InfoStoreBlock from './blocks/InfoStoreBlock'
import LiteCouponCard from './cards/LiteCouponCard'
import Loader from './utils/Loader'
import NoData from './utils/NoData'
import { Query } from 'react-apollo'
import SimilarShopsSlider from './sliders/SimilarShopsSlider'
import SliderShop from '../Components/sliders/SliderShop'
import StatisticManager from './shared/StatisticManager'
import TextBlock from './blocks/TextBlock'

export default class Store extends Component {

  slugToName = (slug) => {
    return slug.replace(/-/g, ' ');
  }

  rankShops(array) {
    const unordered = this.shuffleShops(array.filter(x => x.ordre === 0));
    const orderly = array.filter(x => x.ordre > 0).sort((a, b) => a.ordre - b.ordre);
    return [...orderly, ...unordered];
  }

  shuffleShops(array) {
    array.map(x => x.ordreAffichage = Math.floor(Math.random() * 1000))
    return array.sort((a, b) => a.ordreAffichage - b.ordreAffichage);
  }

  render() {
    return (
      <div className="main-container store-container">
        <Helmet>
          <title>Du côté de ma ville | {this.slugToName(this.props.match.params.shop)}</title>
          <meta property="og:title" content={`Du côté de ma ville | ${this.slugToName(this.props.match.params.shop)}`} />
          <meta property="og:url" content={window.location.href} />
        </Helmet>

        <Query query={GET_SHOP} variables={{ shop: this.props.match.params.shop, date: new Date() }}>
        {({loading, error, data: { enseignes }}) => {
          if (loading || error) return <Loader />
            const shop = enseignes[0]
            const photos = [shop.photo, shop.photo_2, shop.photo_3].filter(x => x !== '').filter(x => x !== null)
            let similarShops = shop.enseignes_similaire;
            let category;
            let subCategory;

            if (similarShops && similarShops.length > 0) {
              similarShops = similarShops.filter(x => x.ordre > 0).length > 0 ? this.rankShops(similarShops) : this.shuffleShops(similarShops);
            }

            if (shop.souscategories.length > 0) {
              subCategory = {name: shop.souscategories[0].souscategorie.nom, slug: shop.souscategories[0].souscategorie.slug};
              category = {name: shop.souscategories[0].souscategorie.categorie.nom, slug: shop.souscategories[0].souscategorie.categorie.slug};
            }

            const statsExist = shop.statistiques && shop.statistiques.compteur >= 0 ? true : false;
            const stats = shop.statistiques && shop.statistiques.compteur ? shop.statistiques.compteur : 0;

            return (
              <div>
                <StatisticManager id={shop.id} stats={stats} shop={true} exist={statsExist} />
                <Breadcrumbs
                  city={shop.ville.nom}
                  subcat={subCategory.name}
                  cat={category.name}
                  shop={shop.nom}
                  citySlug={shop.ville.slug}
                  catSlug={category.slug}
                  subcatSlug={subCategory.slug}
                  shopSlug={shop.slug}
                />

                <div className="row mb-3">
                  <div className="col-12 col-md-8">
                    <div className="card store-header-card mb-3">
                      <SliderShop imgs={photos} video={shop.video} />
                    </div>

                    {shop.promotions.length > 0 ?
                    shop.promotions.map((promo, index) => {
                      return (
                        <div key={promo.slug} className="col-sm-12 p-0">
                          <LiteCouponCard
                            slug={promo.slug}
                            name={promo.nom}
                            validity={promo.date_fin}
                            img={promo.image ? promo.image : promo.logo}
                            condition={promo.condition}
                            partConditions={promo.conditions_particulieres}
                            id={promo.id}
                            creationDate={promo.date_creation}
                          />
                        </div>
                      )
                    })
                    : <NoData text="Aucune promotion pour le moment." />}

                    <TextBlock content={shop.description} />

                    {similarShops && similarShops.length > 0 ? <SimilarShopsSlider shops={similarShops} /> : null}

                    <DownloadAppBlock />
                  </div>

                  <div className="col-12 col-md-4">
                    <InfoStoreBlock
                      name={shop.nom}
                      logo={shop.logo}
                      site={shop.site}
                      video={shop.video}
                      fb={shop.facebook}
                      insta={shop.instagram}
                      twitter={shop.twitter}
                      linkedin={shop.linkedin}
                      address={shop.adresse}
                      visit={shop.visit}
                    />
                    <ContactStoreBlock
                      address={shop.adresse}
                      phone={shop.telephone}
                      mail={shop.email}
                      map={shop.ville.map}
                      fixe={shop.fixe}
                    />
                  </div>
                </div>
              </div>
            )
          }}
        </Query>
      </div>
    );
  }
}
