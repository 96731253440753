import React, { Component } from 'react'

import { Link } from 'react-router-dom'
import { isNewItem } from './../helpers/newItemHelper.js'

export default class CouponCard extends Component {

  formattedDate(unformattedDate) {
    const date = new Date(unformattedDate);
    const d = date.getDate();
    const mm = date.getMonth()+1;
    const yyyy = date.getFullYear();

    return `${d < 10 ? '0'+d : d}/${mm < 10 ? '0'+mm : mm}/${yyyy}`;
  }

  render() {
    const { citySlug, shopSlug, photo, name, text, validity, address, logo, creationDate } = this.props;

    return (
      <div className="card coupon-card my-card mb-3">
        <Link to={`/villes/${citySlug}/boutiques/${shopSlug}`}>
      
          <div className="view">
            <img className="card-img-top" src={photo} alt={name} />
          </div>
        
          <div className="card-body card-body-cascade">
            <div className="row">
              <div className="col-sm-12">
                <span className="offer">{text}</span>
                <div className="date">Valable jusqu'au {this.formattedDate(validity)}</div>
                {isNewItem(creationDate) && <span className="dcdmv-badge badge bg-warning rounded-pill">Nouveau!</span>}
              </div>
            </div>

            <div className="row mt-2">
              <div className="col-sm-12">
                <div className="card-text">{name}</div>
              </div>
              <div className="col-sm-8">
                <span>{address}</span>
              </div>
              <div className="col-sm-4">
                <img className="logo" src={logo} alt={name} />
              </div>
            </div>
          </div>
        </Link>

        <div className="card-footer">
          <Link to={`/villes/${citySlug}/boutiques/${shopSlug}`} className="print-btn">
            Voir l'offre
          </Link>
        </div>
      </div>
    );
  }
}
