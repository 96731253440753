import React from 'react';
import AppAndFollow from './static/AppAndFollow'
import SelectCityBlock from './blocks/SelectCityBlock'
import CategoriesWithIconsBlock from './blocks/CategoriesWithIconsBlock'
import CitiesMosaic from './blocks/CitiesMosaic'
import HomeParallax from './static/HomeParallax'
import HomeText from './static/HomeText'
import { Helmet } from "react-helmet"

export default class Home extends React.PureComponent {
  render() {
    return (
      <div className="cities-container">
        <Helmet>
          <title>Du côté de ma ville | Accueil</title>
          <meta property="og:title" content="Du côté de ma ville | Accueil" />
          <meta property="og:url" content="https://ducotedemaville.fr/" />
        </Helmet>

        <SelectCityBlock />
        <CitiesMosaic />
        <CategoriesWithIconsBlock />
        <HomeText />
        <HomeParallax />
        <AppAndFollow />
      </div>
    )
  }
}
