import React from 'react'
import appStore from './../../static/apple-store.png'
import playStore from './../../static/play-store.png'

const AppAndFollow = () => (
  <div className="app-follow">
    <h5>Télécharger notre application</h5>
    <h5 className="spe">&</h5>
    <h5>Suivez-nous</h5>
        
    <div className="app-follow-content">
      <div className="apps">
        <a target="_blank" rel="noreferrer noopener" href="https://itunes.apple.com/app/id1449828446">
          <img src={appStore} alt="" />
        </a>

        <a target="_blank" rel="noreferrer noopener" href="https://play.google.com/store/apps/details?id=cote.ville.app.als">
          <img src={playStore} alt="" />
        </a>
      </div>

      <div className="divider"></div>

      <div className="fb">
        <a target="_blank" rel="noreferrer noopener" href="http://facebook.com/DuCotedemaVille/">
          <i className="fab fa-facebook" /> Retrouvez nous aussi sur Facebook !
        </a>
      </div>
    </div>
  </div>
);

export default AppAndFollow